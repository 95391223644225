<template>
    <div class="other">
        <h1>Other</h1>
        <div class="gallery-wrapper flex flex-row h-50">
            <ThumbnailLink v-for="link in list"  v-bind:key="link.target" :imgsrc="link.src" :alttxt="link.alt" :hreftarget="link.target" />
        </div>
    </div>
</template>
<script>
import ThumbnailLink from "@/components/ThumbnailLink.vue"
export default{
    components: {
        ThumbnailLink

    },
    data () {
        return { 
            list: [
              { src: 'mechanist.png', position:'top', alt: 'Original Character', target: '/characters'},
              //{ src: 'comms/xilatr_saryna.png', alt: 'Commissions & Fanart', target: '/commissions'},
              { src: 'comms/MWYNHAU_chiba-mushrooms.png', alt: 'Commissions & Fanart', target: '/commissions'},
              { src: 'plein/windowsnow.png', alt: 'Plein Air Journal', target: '/pleinair'},
              { src: 'greencurly.png', alt: 'Portraits', target: '/portraits'},
              { src: 'mousie.png', alt: 'Animals', target: '/animals'},
              { src: 'boxer.png', alt: 'Studies', target: '/studies'},
              { src: 'design/3y3_00.png', position:'top', alt: 'Design', target: '/design'},
              { src: 'scrap/jar.png', position:'top', alt: 'sketches wip', target: '/scrap'},
            ]
        }
    }

}
</script>
